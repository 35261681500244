@import "../../variables";

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 260px;
  box-sizing: border-box;

  .header {
    background-color: $AdminPrimary;
    color: white;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    .logo {
      font-size: 24px;
    }
    .subTitle {
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
      margin: 0;
    }
  }
  .container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px 16px 16px;
    .nav {
      flex-grow: 1;
    }

    .navList {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .navItem {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 1em;
      transition: background-color 0.3s;
      margin-bottom: 8px;
    }

    .navLink {
      display: flex;
      align-items: center;
      color: inherit;
      font-size: 0.95em;
      font-weight: 600;
      text-decoration: none;
      width: 100%;
      padding: 8px 4px;
      border-radius: 8px;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: $WhiteHover;
      }
    }

    .active {
      background-color: $AdminPrimary;
      font-weight: 600;
      color: #ffffff;
      cursor: default;
      &:hover {
        background-color: $AdminPrimary;
      }
    }

    .icon {
      margin-right: 10px;
      font-size: 1.5em;
    }

    .profileImage {
      width: 40px;
      height: 40px;
      margin-right: 4px;
    }

    .profileContainer {
      display: flex;
      align-items: center;
    }

    .roleContainer {
      display: inline-block;
      padding: 2px 4px;
      border: $Border solid 1px;
      border-radius: 6px;
      background-color: $WhiteHover;
    }

    .role {
      margin: 0;
      font-size: 12px;
      font-weight: bold;
      color: $AdminPrimary;
      font-family: "Inter", sans-serif;
      word-break: keep-all;
    }

    .profileName {
      margin: 0;
      font-size: 18px;
      margin-bottom: 4px;
    }

    .profileEmail {
      margin: 0;
      font-size: 14px;
      color: $TextLabel;
    }
  }
}
