.managerContainer {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.pagePath {
  display: flex;
  align-items: center;
}

.text {
  font-size: 15px;
  margin-right: 10px;
}

.managerSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.sectionTitle {
  font-size: 18px;
  margin: 0;
}

.buttonOptions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editButton {
  margin-right: 10px;
  width: 150px;
}

.deleteButton {
  width: 150px;
}

.separator {
  height: 1px;
  background-color: rgba(3, 3, 3, 0.349);
  border: none;
  margin-bottom: 20px;
}

.detailItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.detailLabel {
  /* font-weight: 600; */
  font-size: 15px;
  margin-bottom: 5px;
  color: #333;
}

.detailContent {
  padding: 2px;
  color: #555;
}

.deleteButton {
  width: 150px;
  background-color: transparent;
  border: 2px solid #ff0000;
  color: #ff0000;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.deleteButton:hover {
  background-color: #ff0000;
  color: white;
}
