/* .videoPlay {} */
@import "../../../../variables";

.videoPlayer {
  width: 100%;
  height: 100%;
  justify-content: center;
  border-radius: 8px;
}

.groupIcon {
  font-size: 2em;
  color: $Button;
}

.resetVideos {
  margin-left: auto;
}

.playIcon {
  flex-shrink: 0;
  color: gray;
  font-size: 1.3em;
  cursor: pointer;
}
.playingIcon {
  color: $Green500;
  font-size: 1.3em;
}

.playingVideo {
  background-color: $Green50;
  border: 1px solid $Green300;
  p {
    font-weight: 600;
    color: $Green600;
  }
  &:hover {
    background-color: $Green50;
    cursor: default;
  }
}

.deleteIcon {
  flex-shrink: 0;
  cursor: pointer;
}
.detail {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 10px;
}

.title {
  margin: 0 0 5px 0;
  padding: 0;
  font-size: 18px;
  color: $Text;
}

.description {
  margin: 0;
  padding: 0;
  font-size: 15px;
  color: $Text;
}

.container {
  display: flex;
  flex-direction: column;
}

.videosContainer {
  padding: 16px;
  height: auto;
  border-radius: 10px;
  background-color: white;
  margin-top: 24px;
}

.selectVideo {
  cursor: pointer;
  position: relative;
}

.selectVideo:hover::after {
  content: "リストに追加";
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}
.tagContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  .tag {
    background-color: #f0f0f0;
    border-radius: 4px;
    padding: 2px 6px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    color: #333;
  }
}
