@import "../../../../variables.scss";

.form {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  & > div {
    font-size: 12px;
  }
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  img {
    width: 24px;
    height: 24px;
  }
}

.boldText {
  font-weight: 700;
  font-size: 14px;
  color: #333;
  margin-right: 1rem;
}

.level {
  width: 24px;
  font-weight: 700;
  font-size: 14px;
  padding-right: 8px;
  text-align: right;
  color: #333;
  flex-shrink: 0;
}

.text {
  color: $TextLabel;
  font-weight: 500;
  font-size: 12px;
  max-width: 250px;
}

.videoContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .videoRow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .videoTitle {
    color: $TextLabel;
    font-size: 13px;
    font-weight: 400;
  }
}

.thumbnail {
  height: 30px;
  aspect-ratio: 3 / 2;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 4px;
}

.line {
  height: 25px;
  width: 1px;
  background-color: gray;
  margin-left: 10px;
  margin-right: 10px;
}

.chart {
  width: 100%;
  height: 80%;
}
