/* videos.module.scss */
@import "../../../variables";

.container {
  display: flex;
  /* padding: 20px; */
}

.mainContainer {
  display: flex;
  flex-direction: column;
  margin: 15px;
}

.pagePath {
  display: flex;
  align-items: center;
}

.text {
  font-size: 15px;
  margin-right: 10px;
}

.videoContainer {
  padding: 20px;
  height: auto;
  border-radius: 10px;
  background-color: white;
  width: 100%;
}

.videoSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.sectionTitle {
  font-size: 18px;
  margin: 0;
}

.addButton {
  background-color: $Button;
  color: #fff;
  font-weight: 600;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
}

.videoList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  width: 100%;
  max-width: 1200px;
}

.thumbnailContainer {
  position: relative;
  width: 100%;
  aspect-ratio: 3 / 2;
  border-radius: 8px;
  overflow: hidden;
  background-color: $LightGray;
  .duration {
    position: absolute;
    bottom: 8px;
    right: 8px;
    font-size: 11px;
    font-weight: 600;
    color: $White;
    background-color: $transparentDark;
    padding: 2px 6px;
    border-radius: 4px;
  }
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.detailsContainer {
  position: relative;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.videoTitle {
  margin-top: 0;
  font-size: 16px;
  font-weight: 600;
}

.options {
  margin-right: 10px;
  cursor: pointer;
  font-size: 1.5rem;
}

.dropdownMenu {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 10;
  padding: 10px;
  max-height: 200px;
  overflow-y: auto;
}

.dropdownItem {
  padding: 10px;
  cursor: pointer;
  white-space: nowrap;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  .tag {
    background-color: #f0f0f0;
    border-radius: 4px;
    padding: 2px 6px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    color: #333;
  }
}
