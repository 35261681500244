@import "../variables";

.layout {
  display: flex;
  height: 100vh;
  /* Full height of the viewport */
}

.content {
  flex: 1;
  /* Take up the remaining space next to the sidebar */
  padding: 20px;
  overflow-y: auto;
  /* Ensure content can scroll if it overflows */
  background-color: $LightGray;
  /* Optional: Add a background color for contrast */
}
