@import "../../../../variables";

.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pagePath {
  display: flex;
  align-items: center;
}

.text {
  font-size: 15px;
  margin-right: 10px;
}

.heading {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
}

.formGroup {
  margin-bottom: 16px;
}

.label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.input {
  width: 100%;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input::placeholder {
  color: #999;
}

.option {
  color: black;
}

.submitButton {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-start;
}

.submitButton:hover {
  background-color: #0056b3;
}

.textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: inherit;
  font-size: inherit;
  resize: vertical;
}

.select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
}

.select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

input[type="date"] {
  width: 98%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

input[type="date"]:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.guidlines {
  display: flex;
  flex-direction: column;
}

.text {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.877);
  font-weight: 600;
}

.buttonTitle {
  margin-top: 25px;
  font-size: 15px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.726);
}

.buttonOptions {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 20px;
  align-items: center;
}

.file {
  position: relative;
  width: 50%;
  margin-right: 20px;
}

.fileInput {
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}

.fileLabel {
  display: inline-block;
  width: 100%;
  padding: 10px;
  background-color: #f3f4f6;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  font-size: 14px;
  color: #6b7280;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fileLabel:hover {
  background-color: #e5e7eb;
}

.templateButton {
  width: 250px;
  border: 2px solid rgba(59, 130, 246, 1);
  border-radius: 4px;
  background-color: white;
  color: rgba(59, 130, 246, 1);
  font-size: 15px;
  font-weight: 600;
  padding: 10px;
  cursor: pointer;
}

.templateButton:hover {
  background-color: rgba(59, 130, 246, 0.1);
}

.alert {
  margin: 12px 0;
  padding: 10px;
  border: 2px solid rgb(59, 130, 246);
  background: rgba(59, 130, 246, 0.1);
  border-radius: 4px;
  li {
    font-size: 12px;
    line-height: 1.5;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    font-weight: bold;
  }
}
