.editContainer {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.pagePath {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.text {
  font-size: 15px;
  margin-right: 10px;
  color: #333;
}

.heading {
  margin-bottom: 20px;
}

.sectionTitle {
  font-size: 18px;
  margin: 0;
  color: #333;
}

.editForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.label {
  font-size: 16px;
  font-weight: 500;
  color: #555;
}

.input {
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: border-color 0.2s, box-shadow 0.2s;
}

.input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

.textarea {
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  resize: vertical;
  min-height: 100px;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

.submitButton {
  align-self: flex-start;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.submitButton:hover,
.submitButton:focus {
  background-color: #0056b3;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  outline: none;
}
