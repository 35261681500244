@import "variables.scss";

/*** The new CSS reset - version 1.11.1 (last updated 24.10.2023) ***/

*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg*, symbol*)) {
  all: unset;
  display: revert;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  color: $Text;
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-family: "Helvetica Neue", "Helvetica", "Hiragino Sans",
    "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
}
body {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
p {
  margin: 0;
}
a,
button {
  border: none;
  cursor: pointer;
}
ol,
ul,
menu,
summary {
  list-style: none;
}
img {
  max-inline-size: 100%;
  max-block-size: 100%;
}
table {
  border-collapse: collapse;
}
input,
textarea {
  border: none;
  // outline-color: $BorderForm;
  -webkit-user-select: auto;
  &::placeholder {
    color: $TextPlaceholder;
  }
}
label {
  font-weight: 600;
  color: $TextLabel;
}
textarea {
  white-space: revert;
}
meter {
  -webkit-appearance: revert;
  appearance: revert;
}
:where(pre) {
  all: revert;
  box-sizing: border-box;
}
::placeholder {
  color: unset;
}
:where([hidden]) {
  display: none;
}
:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}
:where([draggable="true"]) {
  -webkit-user-drag: element;
}
:where(dialog:modal) {
  all: revert;
  box-sizing: border-box;
}
