// colors
$AdminPrimary: #8227f5;
$ClientPrimary: #00DE97;

$White: #ffffff;
$WhiteHover: #f8f7f7;
$LightGray: #f0efef;
$LightGrayHover: #f3f3f3;
$transparentDark: rgba(0, 0, 0, 0.7);

$Red: #f63b3b;
$RedHover: #f4d9d9;

$Button: #3b82f6;
$ButtonHover: #2563eb;
$ButtonActive: #1247b9;
$ButtonDisabled: #d6d7d8;
$ButtonGray: #393a3b;
$ButtonGrayHover: #0b1017;

$Text: #0f131a;
$TextLabel: #616771;
$TextInput: #6b7280;
$TextDisabled: #9ca3af;
$TextPlaceholder: #e6e6e6;
$TextLight: #8d8e90;

$Border: #f1f1f1;
$BorderForm: #e4e4e4;

$Red600: #dc2626;
$Orange500: #f97316;
$Yellow500: #eab308;
$Lime500: #84cc16;
$Green50: #ecfdf5;
$Green200: #bbf7d0;
$Green300: #86efac;
$Green500: #22c55e;
$Green600: #059669;

// sizing
$trainingSidebarWidth: 360px;
