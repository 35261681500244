.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.loginBox {
  background-color: white;
  border-radius: 10px;
  width: 450px;
  height: 360px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.top {
  background-color: #16afa2;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clientTop {
  background-color: #151ea3c7;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  margin: 0;
  font-size: 1.2em;
  color: white;
  font-weight: normal;
}

.inputContainer {
  padding: 40px;
}

.label {
  font-size: 18px;
  font-weight: 500;
  color: black;
  margin: 0;
  padding: 0;
}

.inputGroup {
  margin-bottom: 20px;
}

.inputGroup input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 1em;
  background-color: white;
}

.loginButton {
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 10px;
}

.loginButton:hover {
  background-color: #444;
}

.toast-container {
  width: 100px;
}

.toast-message {
  font-size: 14px;
  padding: 5px;
  border-radius: 10px;
}

.loginFormContainer {
  position: relative;
}

.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
