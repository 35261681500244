@import "../../../variables.scss";

.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90%;
  width: 90%;
  margin: auto;
  border-radius: 10px;
  background-color: white;
  .title {
    font-size: 6rem;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.329);
  }

  .subTitle {
    margin: 0;
    padding: 0;
    color: black;
    font-weight: 800;
  }

  .backButton {
    margin-top: 24px;
    font-size: 14px;
    color: $Button;
    font-weight: 600;
    border: solid 1px $Button;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: transparent;
    cursor: pointer;
  }
}
